<template>
  <div class="container" v-if="content">
    <header class="jumbotron">
      <h3 >Product {{ content.data.name }}</h3>
    </header>
    <div>
        <p>Unit: {{content.data.unit_name}}</p>
        <p>Unit amount: {{content.data.unit_amount}}</p>
        <p>Unit fixed cost: {{content.data.unit_fixed_cost}}</p>
    </div>

    <div>
      <h3>Providers</h3>
      <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Is Base?</th>
              <th scope="col">last cost</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, key) in content.data.providers" v-bind:key="key">
              <td scope="row">{{value.id}}</td>
              <td><router-link :to="{ name: 'product-provider', params: { 'productId': content.data.id, 'providerId': value.id }}">{{value.name}}</router-link></td>
              <td scope="row">
                <div class="form-check form-switch">
                  <input ref="is_base_checkbox" @change="setProviderBase($event, value.id)" class="form-check-input" type="checkbox" id="is_base" :checked="value.is_base == 1">
                </div>
              </td>
              <td>{{ value.last_cost }}</td>
            </tr>
          </tbody>
        </table>
    </div>

  </div>
</template>

<script>
import ProductService from "../services/product.service";
import ProviderService from "../services/provider.service";

// import { onMounted } from "vue";
import { useRoute } from 'vue-router'
export default {
  name: "Product",
  props: {
  },
  methods:{
    setProviderBase(event, provider_id){
      // console.log(event.target.value);
      // const value = this.$refs.myInput.value;
      // console.log(value);
      this.formData = {
        'product_id': this.product_id,
        'provider_id': provider_id
      };
      console.log(this.$refs.is_base_checkbox);
      ProviderService.setProviderBaseToProduct(this.formData).then(
          (response) => {
            this.message = response.data.message;
            window.location.reload(); //@TODO change this behavior
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
    }
  },
  setup() {
    // const route = useRoute()

    // onMounted(() => {
    //   const productId = route.params.productId
    //   console.log(productId);
    // })
  },
  data() {
    return {
      content: "",
      formData: '',
      product_id: '',
      provider_id: '',
    };
  },
  mounted() {
    if (this.loggedIn) {
      // this.$router.push("/profile");
    }
    const route = useRoute()

    ProductService.getProduct(route.params.productId).then(
      (response) => {
        this.content = response.data;
        this.product_id = this.content.data.id
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
};

</script>